import React, { useState, useEffect } from 'react';
import { Table, Modal, Form } from 'antd';
import AuthService from '../services/auth.service';
import moment from 'moment'; // ✅ 用來格式化日期

const UserListComponent = ({ currentUser }) => {
  const [data, setData] = useState([]); // 全部的訪客資料
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]); // 存儲選中的訪客
  const [activeTab, setActiveTab] = useState('pending'); // 'pending' = 未審核, 'approved' = 已審核
  const [form] = Form.useForm();

  useEffect(() => {
    if (!currentUser || currentUser.user.role !== 'admin') {
      window.alert('沒有權限');
      return;
    }

    setLoading(true);
    AuthService.getUserList(['guest', 'stranger'])
      .then((res) => setData(res.data))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [currentUser]);

  // 取得「未審核」和「已審核」的訪客清單
  const pendingUsers = data.filter((user) => user.role === 'stranger');
  const approvedUsers = data.filter((user) => user.role === 'guest');

  // 設定 Table 的多選功能
  const rowSelection = {
    selectedRowKeys: selectedRows.map((item) => item._id),
    onChange: (_, selectedRows) => setSelectedRows(selectedRows),
  };

  // ✅ 批次審核（將 `stranger` 變成 `guest`）
  const handleBatchApprove = () => {
    if (selectedRows.length === 0) {
      Modal.warning({ title: '請選擇至少一筆資料' });
      return;
    }

    const updatedData = selectedRows.map((user) => ({
      ...user,
      role: 'guest',
    }));

    AuthService.batchUpdateUsers(updatedData)
      .then(() => {
        Modal.success({
          title: '批次審核成功，通知信已發送',
          content: `已審核 ${selectedRows.length} 位訪客。`,
        });

        const newData = data.map((user) =>
          selectedRows.some((u) => u._id === user._id)
            ? { ...user, role: 'guest' }
            : user
        );
        setData(newData);
        setSelectedRows([]);
      })
      .catch((err) => {
        console.error(err);
        Modal.error({ title: '審核失敗', content: '請稍後再試。' });
      });
  };

  // ✅ 批次退回（將 `guest` 變成 `stranger`）
  const handleBatchRevert = () => {
    if (selectedRows.length === 0) {
      Modal.warning({ title: '請選擇至少一筆資料' });
      return;
    }

    Modal.confirm({
      title: '確定要批次退回這些帳號嗎？',
      content: `這將把 ${selectedRows.length} 位訪客的審核狀態改為「未審核」。`,
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        const revertData = selectedRows.map((user) => ({
          ...user,
          role: 'stranger',
        }));

        AuthService.batchUpdateUsers(revertData)
          .then(() => {
            Modal.success({ title: '批次退回成功' });

            const newData = data.map((user) =>
              selectedRows.some((u) => u._id === user._id)
                ? { ...user, role: 'stranger' }
                : user
            );
            setData(newData);
            setSelectedRows([]);
          })
          .catch((err) => {
            console.error(err);
            Modal.error({ title: '退回失敗', content: '請稍後再試。' });
          });
      },
    });
  };

  // ✅ 批次註銷（永久刪除帳號）
  const handleBatchDelete = () => {
    if (selectedRows.length === 0) {
      Modal.warning({ title: '請選擇至少一筆資料' });
      return;
    }

    Modal.confirm({
      title: '確定要批次註銷這些帳號嗎？',
      content: `這將永久刪除 ${selectedRows.length} 位訪客，無法恢復。`,
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        const deleteIds = selectedRows.map((user) => user._id);

        AuthService.batchDeleteUsers(deleteIds)
          .then(() => {
            Modal.success({ title: '批次註銷成功' });

            const newData = data.filter(
              (user) => !selectedRows.some((u) => u._id === user._id)
            );
            setData(newData);
            setSelectedRows([]);
          })
          .catch((err) => {
            console.error(err);
            Modal.error({ title: '註銷失敗', content: '請稍後再試。' });
          });
      },
    });
  };

  // 設定 Table 欄位
  const columns = [
    {
      title: '申請日期',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: '10vw',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm') : '未知',
    },
    {
      title: '狀態',
      key: 'role',
      dataIndex: 'role',
      width: '6vw',
      render: (text) => <p>{text === 'guest' ? '已審核' : '未審核'}</p>,
    },
    {
      title: '學校/單位',
      key: 'background',
      dataIndex: 'background',
      width: '6vw',
    },
    {
      title: '姓名',
      key: 'username',
      dataIndex: 'username',
      width: '6vw',
    },
    {
      title: '信箱',
      key: 'email',
      dataIndex: 'email',
      width: '10vw',
    },
    {
      title: '用途',
      key: 'purpose',
      dataIndex: 'purpose',
      width: '6vw',
    },
  ];

  return (
    <div className='container'>
      <br />
      <h3 className='display-4 fs-3 fw-bold'>外部人員管理</h3>
      <hr />

      <div className='btn-group mb-3'>
        <button
          className={`btn btn-warning ${
            activeTab === 'pending' ? 'active' : ''
          }`}
          onClick={() => setActiveTab('pending')}
        >
          未審核訪客（{pendingUsers.length}）
        </button>
        <button
          className={`btn btn-success ${
            activeTab === 'approved' ? 'active' : ''
          }`}
          onClick={() => setActiveTab('approved')}
        >
          已審核訪客（{approvedUsers.length}）
        </button>
      </div>

      <Form form={form} component={false}>
        <Table
          loading={loading}
          bordered
          rowSelection={rowSelection}
          dataSource={(activeTab === 'pending'
            ? pendingUsers
            : approvedUsers
          ).map((item) => ({ ...item, key: item._id }))}
          columns={columns}
          pagination={false}
          style={{ fontSize: 20 }}
        />
      </Form>

      <div className='mt-3'>
        {activeTab === 'pending' && (
          <button
            className='btn btn-danger'
            onClick={handleBatchApprove}
            disabled={selectedRows.length === 0}
          >
            批次審核（{selectedRows.length}）
          </button>
        )}
        {activeTab === 'approved' && (
          <button
            className='btn btn-outline-warning ms-2'
            onClick={handleBatchRevert}
            disabled={selectedRows.length === 0}
          >
            批次退回（{selectedRows.length}）
          </button>
        )}
        <button
          className='btn btn-outline-danger ms-2'
          onClick={handleBatchDelete}
          disabled={selectedRows.length === 0}
        >
          批次註銷（{selectedRows.length}）
        </button>
      </div>
    </div>
  );
};

export default UserListComponent;
