import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL + '/api/file';
// const API_URL = "http://localhost:8080/api/file";

class FileService {
  //上傳標記檔
  post(currentUser, fugudata) {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios
      .post(
        API_URL + '/upload',
        {
          currentUser,
          fugudata,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  }
  SourceUpload(currentUser, sourceData) {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios
      .post(
        API_URL + '/SourceUpload',
        {
          currentUser,
          sourceData,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  }

  //更新使用者明細檔
  put(record) {
    // console.log(record);
    // console.log(`record.id ${record._id}`);
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.put(
      API_URL + '/UserList/' + record._id,
      {
        record,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
}

export default new FileService();
