import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthService from '../services/auth.service';
import { TabTitle } from './changeTitle';
import { Modal, Space } from 'antd';

const LoginComponent = (props) => {
  useEffect(() => {
    TabTitle('登入');
  }, []);
  let { currentUser, setCurrentUser } = props;
  const history = useNavigate();
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [message, setMessage] = useState('');

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleForgotPassword = (e) => {
    history('/forgotPassword');
  };
  const handleLogin = () => {
    AuthService.login(email, password)
      .then((response) => {
        // console.log(response.data);
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        Modal.success({
          title: '登入成功',
          content: `登入成功，返回首頁。`,
          okText: '確認',
        });
        // window.alert("登入成功，返回首頁。");
        setCurrentUser(AuthService.getCurrentUser());
        history('/');
      })
      .catch((error) => {
        // Modal.error({
        //   title: "登入失敗",
        //   content: `登入失敗，原因:${error.response}。`,
        //   okText: "確認",
        // });
        // console.log(error.response);
        setMessage(error.response.data);
      });
  };
  const onKey = (event) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <div className='p-5 col-md-5 container'>
      <div>
        {message && (
          <div
            className='alert alert-danger'
            role='alert'
            style={{ fontSize: '20px' }}
          >
            {message}
          </div>
        )}
        <div className='form-group '>
          <label htmlFor='email' style={{ fontSize: '25px' }}>
            信箱
          </label>
          <input
            onChange={handleChangeEmail}
            type='email'
            className='form-control'
            name='email'
          />
        </div>
        <br />
        <div className='form-group'>
          <label htmlFor='password' style={{ fontSize: '25px' }}>
            密碼
          </label>
          <input
            onChange={handleChangePassword}
            type='password'
            className='form-control'
            name='password'
            onKeyDown={(e) => onKey(e)}
          />
        </div>
        <br />
        <div className='form-group'>
          <button
            onClick={handleLogin}
            className='btn btn-primary btn-block'
            style={{ marginRight: '10px' }}
          >
            <span>登入</span>
          </button>
          <button
            onClick={handleForgotPassword}
            className='btn btn-secondary btn-block'
          >
            <span>忘記密碼</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
