import React, { useState, useEffect } from 'react';
import AuthService from '../services/auth.service';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  let [message, setMessage] = useState('');
  const [countdown, setCountdown] = useState(0); // 30秒倒計時
  // 倒計時效果，每秒減少 1
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer); // 清理計時器
    }
  }, [countdown]);
  const handleForgotPassword = async () => {
    if (!email) {
      setMessage('請輸入 Email');
      return;
    }
    setLoading(true);
    try {
      const response = await AuthService.forgotPassword(email);
      setMessage(response.data);
      setCountdown(30); // 設定 30 秒倒計時
    } catch (err) {
      setMessage(err.response ? err.response.data : '發生錯誤');
    }
    setLoading(false);
  };

  return (
    <div className='p-5 col-md-5 container'>
      <div>
        <h2>忘記密碼</h2>
        {message && (
          <div
            className={`alert ${
              message.includes('已發送') ? 'alert-success' : 'alert-danger'
            }`}
            role='alert'
            style={{ fontSize: '20px' }}
          >
            {message}
          </div>
        )}
        <div className='form-group'>
          <label htmlFor='email' style={{ fontSize: '25px' }}>
            信箱
          </label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            className='form-control'
            name='email'
          />
        </div>
        <br />
        <div className='form-group'>
          <button
            onClick={handleForgotPassword}
            disabled={loading || countdown > 0} // 禁用按鈕條件：發送中 or 倒計時中
            className='btn btn-primary btn-block'
          >
            {loading
              ? '發送中...'
              : countdown > 0
              ? `請稍候 ${countdown} 秒`
              : '發送重設密碼信'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
