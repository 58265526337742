import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SourceService from '../services/source.service';

const SentenceFinder = ({ authorTitle, former, latter, show, onHide }) => {
  const [result, setResult] = useState('');
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [number, setNumber] = useState('');

  // ✅ 當 `show` 或 `authorTitle` 改變時，先取得 `number`
  useEffect(() => {
    if (!show) return;

    async function fetchNumber() {
      try {
        const encodedTitle = encodeURIComponent(authorTitle); // ✅ 避免 URL 解析錯誤
        const res = await SourceService.getNumber(encodedTitle);
        setTitle(authorTitle);
        setNumber(res.data);
      } catch (err) {
        console.error('獲取編號失敗:', err);
      }
    }

    fetchNumber();
  }, [show, authorTitle]);

  // ✅ `number` 拿到後，再取得全文
  useEffect(() => {
    if (!number) return;

    async function fetchText() {
      try {
        const res = await SourceService.getContext(number);
        setText(res.data);
      } catch (err) {
        console.error('獲取全文失敗:', err);
      }
    }

    fetchText();
  }, [number]);

  // ✅ 當 `text` 拿到後，再執行 **前後文查找**
  useEffect(() => {
    if (!text || !former || !latter) return;

    function findContext() {
      // 1. 先找到 `latter`（後分句）
      const cStartIndex = text.indexOf(latter.trim());
      if (cStartIndex === -1) {
        setResult('在全文中找不到後分句');
        return;
      }
      const cEndIndex = cStartIndex + latter.length;
      let extractedText = text.substring(cStartIndex, cEndIndex);
      console.log('Extracted text:', extractedText);
      console.log('former:', former, '!!');

      // 2. 在 `cStartIndex` 之前，找到最後一個 `former`
      let bStartIndex = text.lastIndexOf(former.trim(), cStartIndex);
      if (bStartIndex === -1) {
        setResult('在全文中找不到前分句');
        return;
      }
      const bEndIndex = bStartIndex + former.length;
      extractedText = text.substring(cStartIndex - 20, cEndIndex + 10);
      console.log('Extracted text:', extractedText);

      // 3. A 句（往前找兩個 `。`）
      let aStartIndex = bStartIndex;
      let punctuationCount = 0;
      while (aStartIndex > 0 && punctuationCount < 2) {
        aStartIndex--;
        if (text[aStartIndex] === '。') {
          punctuationCount++;
        }
      }
      if (punctuationCount < 2) {
        aStartIndex = 0; // 若不到兩個句號，則從頭開始
      } else {
        aStartIndex += 1; // 跳過 `。`
      }
      const aSentence = text.slice(aStartIndex, bStartIndex).trim();

      // 4. D 句（往後找第一個 `。`）
      let dEndIndex = text.indexOf('。', cEndIndex);
      if (dEndIndex === -1) {
        dEndIndex = text.length; // 若找不到，則取到全文結尾
      } else {
        dEndIndex += 1; // 包含 `。`
      }
      const dSentence = text.slice(cEndIndex, dEndIndex).trim();

      // ✅ 高亮 B & C
      const highlightedB = `<span style="color: red;">${former}</span>`;
      const highlightedC = `<span style="color: red;">${latter}</span>`;

      // ✅ 組合結果
      const resultSentences = [aSentence, highlightedB, highlightedC, dSentence]
        .filter(Boolean)
        .join('<br /><br />');

      setResult(resultSentences);
    }

    findContext();
  }, [text, former, latter]);

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {number ? `${number}-${title} 前後文查找結果` : '查找中...'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ fontSize: '18px', lineHeight: '1.6' }}
          dangerouslySetInnerHTML={{ __html: result }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={onHide}>
          關閉
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SentenceFinder;
