import React, { useState, useEffect } from 'react';
import { Table, Modal } from 'antd';
import AuthService from '../services/auth.service';

const StaffListComponent = ({ currentUser }) => {
  const [staffData, setStaffData] = useState([]); // 助理 & 管理員列表
  const [guestData, setGuestData] = useState([]); // 已審核訪客
  const [loading, setLoading] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null); // 選擇要新增的訪客

  useEffect(() => {
    if (!currentUser || currentUser.user.role !== 'admin') {
      window.alert('沒有權限');
      return;
    }

    setLoading(true);
    // 獲取助理 & 管理員列表
    AuthService.getUserList(['staff', 'admin'])
      .then((res) => setStaffData(res.data))
      .catch((err) => console.error(err));

    // 獲取已審核訪客
    AuthService.getUserList(['guest'])
      .then((res) => setGuestData(res.data))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [currentUser]);

  // ✅ 註銷助理
  const handleDeleteStaff = (staffId) => {
    Modal.confirm({
      title: '確定要註銷此帳號嗎？',
      content: '此操作將永久刪除該帳號，無法恢復。',
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        AuthService.deleteStaff(staffId)
          .then(() => {
            setStaffData((prev) =>
              prev.filter((staff) => staff._id !== staffId)
            );
            Modal.success({ title: '帳號已註銷' });
          })
          .catch((err) => {
            console.error(err);
            Modal.error({ title: '註銷失敗，請稍後再試' });
          });
      },
    });
  };

  // ✅ 變更權限（同步更新 UI）
  const handleChangeRole = (staffId, newRole) => {
    Modal.confirm({
      title: `確定要將此使用者變更為 ${
        newRole === 'guest' ? '訪客' : newRole === 'admin' ? '管理員' : '助理'
      } 嗎？`,
      content: `該使用者的權限將變更為 ${
        newRole === 'guest' ? '訪客' : newRole === 'admin' ? '管理員' : '助理'
      }。`,
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        AuthService.updateUserRole(staffId, newRole)
          .then(() => {
            if (newRole === 'guest') {
              // ✅ 轉為訪客時，從 `staffData` 移除，加入 `guestData`
              const removedUser = staffData.find(
                (staff) => staff._id === staffId
              );
              if (removedUser) {
                setGuestData((prev) => [...prev, removedUser]); // 加回訪客選單
              }
              setStaffData((prev) =>
                prev.filter((staff) => staff._id !== staffId)
              ); // 從表格移除
            } else {
              // ✅ 轉為管理員或助理時，僅更新 `staffData`
              setStaffData((prev) =>
                prev.map((staff) =>
                  staff._id === staffId ? { ...staff, role: newRole } : staff
                )
              );
            }
            Modal.success({
              title: `成功變更為 ${
                newRole === 'guest'
                  ? '訪客'
                  : newRole === 'admin'
                  ? '管理員'
                  : '助理'
              }！`,
            });
          })
          .catch((err) => {
            console.error(err);
            Modal.error({ title: '權限變更失敗，請稍後再試' });
          });
      },
    });
  };

  // ✅ 新增助理（同步更新 UI）
  const handleAddStaff = () => {
    if (!selectedGuest) {
      Modal.warning({ title: '請選擇要新增的助理帳號' });
      return;
    }

    AuthService.addStaff(selectedGuest)
      .then(() => {
        const newStaff = guestData.find((g) => g._id === selectedGuest);
        if (newStaff) {
          setStaffData((prev) => [...prev, { ...newStaff, role: 'staff' }]); // ✅ 加入助理列表
          setGuestData((prev) => prev.filter((g) => g._id !== selectedGuest)); // ✅ 從訪客列表移除
        }
        setSelectedGuest(null); // ✅ 清空選擇
        Modal.success({ title: '已成功新增助理' });
      })
      .catch((err) => {
        console.error(err);
        Modal.error({ title: '新增失敗，請稍後再試' });
      });
  };

  // ✅ 助理列表欄位（新增「權限」欄位）
  const staffColumns = [
    {
      title: '上次登入時間',
      key: 'lastLogin',
      dataIndex: 'lastLogin',
      width: '10vw',
      render: (text) =>
        text ? new Date(text).toLocaleString() : '未有登入時間', // ✅ 顯示時間
    },
    // {
    //   title: '學校/單位',
    //   key: 'background',
    //   dataIndex: 'background',
    //   width: '6vw',
    // },
    {
      title: '姓名',
      key: 'username',
      dataIndex: 'username',
      width: '6vw',
    },
    {
      title: '信箱',
      key: 'email',
      dataIndex: 'email',
      width: '10vw',
    },
    {
      title: '權限',
      key: 'role',
      dataIndex: 'role',
      width: '6vw',
      render: (text) => (text === 'admin' ? '管理員' : '助理'),
    },
    {
      title: '操作',
      key: 'operation',
      width: '18vw',
      render: (_, record) => (
        <div className='btn-group'>
          <button
            className='btn btn-outline-danger btn-sm'
            onClick={() => handleDeleteStaff(record._id)}
          >
            註銷
          </button>
          {record.role !== 'admin' && (
            <button
              className='btn btn-outline-warning btn-sm ms-2'
              onClick={() => handleChangeRole(record._id, 'guest')}
            >
              設為訪客
            </button>
          )}
          {record.role === 'staff' && (
            <button
              className='btn btn-outline-primary btn-sm ms-2'
              onClick={() => handleChangeRole(record._id, 'admin')}
            >
              設為管理員
            </button>
          )}
          {record.role === 'admin' && (
            <button
              className='btn btn-outline-secondary btn-sm ms-2'
              onClick={() => handleChangeRole(record._id, 'staff')}
            >
              設為助理
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className='container'>
      <br />
      <h3 className='display-4 fs-3 fw-bold'>內部人員管理</h3>
      <hr />

      {/* 新增助理區塊 - Bootstrap 風格 */}
      <div className='mb-3 d-flex'>
        <select
          className='form-select w-50 me-2'
          onChange={(e) => setSelectedGuest(e.target.value)}
          value={selectedGuest || ''}
        >
          <option value=''>選擇已審核訪客</option>
          {guestData.map((guest) => (
            <option key={guest._id} value={guest._id}>
              {guest.username} - {guest.email}
            </option>
          ))}
        </select>
        <button className='btn btn-primary' onClick={handleAddStaff}>
          新增助理
        </button>
      </div>

      {/* 助理列表 */}
      <Table
        loading={loading}
        bordered
        dataSource={staffData.map((item) => ({ ...item, key: item._id }))}
        columns={staffColumns}
        pagination={false}
        style={{ fontSize: 20 }}
      />
    </div>
  );
};

export default StaffListComponent;
