import React, { useState, useEffect } from 'react';
import AuthService from '../services/auth.service';
import { useSearchParams } from 'react-router-dom';
const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // 從 URL 取得 Token
  let [message, setMessage] = useState('');
  useEffect(() => {
    if (!token) {
      setMessage('無效的或過期的重設密碼連結');
    }
  }, [token]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(token);
    try {
      const response = await AuthService.resetPassword(newPassword, token);

      setMessage(response.data);
    } catch (err) {
      setMessage(err.response ? err.response.data : '發生錯誤');
    }
  };

  return (
    <div className='p-5 col-md-5 container'>
      <div>
        <h2>重設密碼</h2>
        {message && (
          <div
            className={`alert ${
              message.includes('成功') ? 'alert-success' : 'alert-danger'
            }`}
            role='alert'
            style={{ fontSize: '20px' }}
          >
            {message}
          </div>
        )}

        <br />
        <div className='form-group'>
          <label htmlFor='password' style={{ fontSize: '25px' }}>
            新密碼
          </label>
          <input
            onChange={(e) => setNewPassword(e.target.value)}
            type='password'
            className='form-control'
            name='password'
          />
        </div>
        <br />
        <div className='form-group'>
          <button onClick={handleSubmit} className='btn btn-primary btn-block'>
            <span>重設密碼</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
