import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomeComponent from './components/home-component';
import NavComponent from './components/nav-component';
import RegisterComponent from './components/register-component';
import LoginComponent from './components/login-component';
import EachTable from './components/eachTable-component';
import FooterComponent from './components/footer-component';
import AboutComponent from './components/about-component';
import SourceComponent from './components/source-component';
import AuthService from './services/auth.service';
import AnalysisComponent from './components/analysis-component';
import WorkReportComponent from './components/workReport-component';
import DirectionsComponent from './components/directions-component';
import UserListComponent from './components/userlist-component';
import StaffListComponent from './components/stafflist-component';
import ForgotPassword from './components/forgotPassword-component';
import ResetPassword from './components/resetPassword-component';

function App() {
  let [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  return (
    <div>
      <NavComponent currentUser={currentUser} setCurrentUser={setCurrentUser} />
      {/* <AutoLogout currentUser={currentUser} setCurrentUser={setCurrentUser} /> */}

      <Routes>
        <Route path='/forgotPassword' element={<ForgotPassword />}></Route>

        <Route path='/resetPassword' element={<ResetPassword />}></Route>
        <Route
          path='/'
          element={
            <HomeComponent
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          }
        ></Route>
        <Route path='/about' element={<AboutComponent />}></Route>
        {/* <Route path="/member" element={<MemberComponent />}></Route> */}
        <Route path='/register' element={<RegisterComponent />}></Route>
        {/* <Route path='/SentenceFinder' element={<SentenceFinder />}></Route> */}
        <Route
          path='/login'
          element={
            <LoginComponent
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          }
        ></Route>
        <Route
          path='/userList'
          element={
            <UserListComponent
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          }
        ></Route>
        <Route
          path='/staffList'
          element={
            <StaffListComponent
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          }
        ></Route>
        <Route
          path='/eachTable/:_NO'
          element={
            <EachTable
              IsOn
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          }
        ></Route>
        <Route
          path='/source'
          element={
            <SourceComponent
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          }
        ></Route>
        <Route
          path='/analysis'
          element={
            <AnalysisComponent
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          }
        ></Route>
        <Route
          path='/workreport'
          element={
            <WorkReportComponent
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          }
        ></Route>
        <Route
          path='/directions'
          element={
            <DirectionsComponent
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          }
        ></Route>
      </Routes>
      <FooterComponent></FooterComponent>
    </div>
  );
}

export default App;
