import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL + '/api/user';
// const API_URL = "http://localhost:8080/api/user";

class AuthService {
  login(email, password) {
    return axios.post(API_URL + '/login', { email, password });
  }
  logout() {
    localStorage.removeItem('user');
  }

  register(username, email, password, role, background, purpose, otherPurpose) {
    return axios.post(API_URL + '/register', {
      username,
      email,
      password,
      role,
      background,
      purpose,
      otherPurpose,
    });
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  checkJwt() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.post(API_URL + '/checkJwt', {
      headers: {
        Authorization: token,
      },
    });
  }
  // 🔹 忘記密碼 - 傳送 Email
  forgotPassword(email) {
    return axios.post(API_URL + '/forgot-password', { email });
  }

  // 🔹 重設密碼 - 傳送新密碼
  resetPassword(newPassword, token) {
    return axios.post(API_URL + '/reset-password', {
      newPassword,
      token,
    });
  }

  // 取得使用者列表
  getUserList(roles) {
    let token = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).token
      : '';

    return axios.get(API_URL + '/UserList', {
      params: { roles }, // ✅ 改用 params 來傳遞角色陣列
      headers: {
        Authorization: token,
      },
    });
  }
  // 批次審核訪客
  batchUpdateUsers(updatedData) {
    let token = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).token
      : '';

    return axios.put(API_URL + '/batch-update-users', updatedData, {
      // ✅ 直接發送陣列
      headers: {
        Authorization: token,
      },
    });
  }
  // 批次註銷訪客
  batchDeleteUsers(deleteIds) {
    let token = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).token
      : '';

    return axios.delete(API_URL + '/batch-delete-users', {
      data: { userIds: deleteIds }, // ✅ `DELETE` 的 body 必須放在 `data`
      headers: {
        Authorization: token,
      },
    });
  }

  // 刪除助理帳號
  deleteStaff(staffId) {
    let token = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).token
      : '';

    return axios.delete(`${API_URL}/delete-staff/${staffId}`, {
      headers: {
        Authorization: token,
      },
    });
  }

  // 新增助理帳號
  addStaff(addId) {
    let token = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).token
      : '';

    return axios.put(
      `${API_URL}/add-staff/${addId}`, // ✅ `PUT` 使用 URL 參數
      {}, // `PUT` 需要一個 `body`，這裡不需要任何內容
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // ✅ 更新助理角色
  updateUserRole(userId, newRole) {
    let token = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).token
      : '';

    return axios.put(
      `${API_URL}/update-role/${userId}`,
      { newRole },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
}

export default new AuthService();
