import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthService from '../services/auth.service';
import { Modal } from 'antd';

const NavComponent = (props) => {
  let { currentUser, setCurrentUser } = props;

  const history = useNavigate();
  const handleLogout = () => {
    AuthService.logout();
    // window.alert("登出成功，返回首頁。");
    Modal.success({
      title: '登出成功',
      content: `登出成功，返回首頁。`,
      okText: '確認',
    });
    history('/');
    setCurrentUser(null);
  };
  useEffect(() => {
    AuthService.checkJwt()
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log('權限失效');
          setCurrentUser(null);
        }
      });
  }, []);
  return (
    <div>
      <nav className='navbar navbar-expand-lg navbar-dark bg-dark sticky-top'>
        <div className='container-fluid'>
          <h5 className='navbar-brand'>現代漢語複句語料庫</h5>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <Link className='nav-link' to='/'>
                  首頁
                </Link>
              </li>

              <li className='nav-item'>
                <Link className='nav-link' to='/about'>
                  計畫介紹
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/directions'>
                  標記說明
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/member">
                  計畫成員
                </Link>
              </li> */}
              {!currentUser && (
                <li className='nav-item'>
                  <Link className='nav-link' to='/register'>
                    註冊
                  </Link>
                </li>
              )}

              {currentUser && (
                <li className='nav-item'>
                  <Link className='nav-link' to='/source'>
                    語料目錄
                  </Link>
                </li>
              )}
              {currentUser && currentUser.user.role === 'guest' && (
                <li className='nav-item'>
                  <Link className='nav-link' to='/eachTable/all'>
                    語料檢索
                  </Link>
                </li>
              )}
              {currentUser &&
                (currentUser.user.role === 'staff' ||
                  currentUser.user.role === 'admin') && (
                  <li className='nav-item'>
                    <Link className='nav-link' to='/eachTable/all'>
                      全語料編輯
                    </Link>
                  </li>
                )}
              {currentUser &&
                (currentUser.user.role === 'staff' ||
                  currentUser.user.role === 'admin') && (
                  <li className='nav-item'>
                    <Link className='nav-link' to='/analysis'>
                      語料統計
                    </Link>
                  </li>
                )}
              {currentUser &&
                (currentUser.user.role === 'staff' ||
                  currentUser.user.role === 'admin') && (
                  <li className='nav-item dropdown'>
                    <a
                      className='nav-link dropdown-toggle'
                      href='#'
                      id='navbarDropdown'
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      內部作業
                    </a>
                    <ul
                      className='dropdown-menu'
                      aria-labelledby='navbarDropdown'
                    >
                      <li>
                        <a className='dropdown-item' href='/workreport'>
                          助理工作報表
                        </a>
                      </li>
                      {currentUser && currentUser.user.role === 'admin' && (
                        <>
                          <li>
                            <a className='dropdown-item' href='/userList'>
                              外部人員管理
                            </a>
                          </li>
                          <li>
                            <a className='dropdown-item' href='/staffList'>
                              內部人員管理
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>
                )}
            </ul>
            <div className='navbar-nav flex-row flex-wrap ms-md-auto'>
              {currentUser && (
                <div
                  className='nav-item col-6 col-md-auto'
                  style={{
                    marginRight: '20px',
                    paddingTop: '10px',
                  }}
                >
                  <h5 className='text-light'>
                    {currentUser.user.username}，您好!
                  </h5>
                </div>
              )}
              {!currentUser && (
                <li className='nav-item'>
                  <Link
                    type='button'
                    className='btn btn-light btn-font-size-lg'
                    to='/login'
                  >
                    登入
                  </Link>
                </li>
              )}
              {currentUser && (
                <li className='nav-item'>
                  <button
                    type='button'
                    className='btn btn-light btn-font-size-lg'
                    onClick={handleLogout}
                  >
                    登出
                  </button>
                </li>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavComponent;
// <div>
//   <div classNameName="container">
//     <nav classNameName="navbar navbar-expand-lg navbar-light bg-light fixed-top">
//       <div classNameName="container-fluid">
//         <div classNameName="collapse navbar-collapse" id="navbarNav">
//           <ul classNameName="navbar-nav">
//             <li classNameName="navbar-brand">
//               <Link classNameName=" nav-link active " to="/">
//                 現代漢語複句語料庫
//               </Link>
//             </li>
//             <li classNameName="nav-item">
//               <Link classNameName=" nav-link active " to="/">
//                 首頁
//               </Link>
//             </li>
//             {!currentUser && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/register">
//                   註冊
//                 </Link>
//               </li>
//             )}
//             {!currentUser && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/login">
//                   登入
//                 </Link>
//               </li>
//             )}
//             {currentUser && (
//               <li classNameName="nav-item">
//                 <Link onClick={handleLogout} classNameName="nav-link" to="#">
//                   登出
//                 </Link>
//               </li>
//             )}
//             {/* {currentUser && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/profile">
//                   Profile
//                 </Link>
//               </li>
//             )}
//             {currentUser && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/course">
//                   Course
//                 </Link>
//               </li>
//             )} */}
//             {/* {currentUser && currentUser.user.role == "instructor" && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/postCourse">
//                   Post Course
//                 </Link>
//               </li>
//             )} */}
//             {currentUser && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/source">
//                   文本目錄
//                 </Link>
//               </li>
//             )}
//             {currentUser && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/fugu">
//                   複句查詢
//                 </Link>
//               </li>
//             )}
//             {currentUser && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/editfugu">
//                   複句編輯
//                 </Link>
//               </li>
//             )}
//             {/* {currentUser && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/postFugu">
//                   複句新增
//                 </Link>
//               </li>
//             )} */}
//             {/* {currentUser && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/upload">
//                   複句上傳
//                 </Link>
//               </li>
//             )} */}
//             {/* {currentUser && currentUser.user.role == "student" && (
//               <li classNameName="nav-item">
//                 <Link classNameName="nav-link" to="/enroll">
//                   Enroll
//                 </Link>
//               </li>
//             )} */}
//           </ul>
//         </div>
//       </div>
//     </nav>
//   </div>
// </div>
